

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import FunnelComponent from "@/components/Flowbuilder/Builder/Components/FunnelComponent";


@Options({
    components: {MappingTree, DropDownSearch, Select},
    props: {
        collectedData: Array,
        parentComponent: {},
        component: null
    },
    emits: ['saveComponent'],
    data() {
        return {
            mappingOptions: [],
            field: '',
            comparison: '',
            value: '',
            selectedOption: null,
            listeners: []
        }
    },
    beforeMount() {
        if (typeof this.$props.component !== 'undefined') {

            const funnel : FunnelComponent = this.$props.component as FunnelComponent;

            this.field = funnel.getField();
            this.value = funnel.getValue();
            this.comparison = funnel.getComparison();
        }
    },
    methods: {
        valueSelected(path: string): void
        {
            this.value = path;
        },
        fieldSelected(path: string) {
            this.field = path;
        },
        setField(field: string): void
        {
            this.field = field;
        },
        save() : void
        {
            const funnel : FunnelComponent = this.$props.component as FunnelComponent;

            funnel.setValue(this.value)
            funnel.setField(this.field)
            funnel.setComparison(this.comparison)

            const e = new CustomEvent('updateComponent', {
                detail: {
                    component: funnel,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                }
            });

            window.dispatchEvent(e);
        },
        create() : void
        {
            const component: FunnelComponent = new FunnelComponent(null, this.field, '', '');
            const view : ViewComponent = new ViewComponent(ComponentType.FUNNEL, null, component, 0, 0, [])
            this.$emit('saveComponent', view)
        }
    }
})
export default class CreateFunnel extends Vue{}

