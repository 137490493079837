
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";

export default class ComponentDraw {

    instance : BrowserJsPlumbInstance;

    constructor(instance: BrowserJsPlumbInstance) {
        this.instance = instance;
    }

    mouse: any = {x: 0, y: 0};

    draw(px: number, py: number, component: ViewComponent, position: number) : HTMLElement
    {
        const div:HTMLElement = document.createElement('div');
        div.className = 'component-item ';
        div.style.left = px + 'px';
        div.style.top = py + 'px';

        // ugly hack but jsplumb refuses to fire click events
        div.onmousedown = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            if (target.getAttribute('data-trash') === '1') {

                const e = new CustomEvent('removeComponent', {
                    detail: {
                        component: component,
                        position: position
                    }
                });
                window.dispatchEvent(e);

                return;
            }
        }

        div.onmouseup = (event: MouseEvent) => {
            //if (this.mouse.x === event.clientX && this.mouse.y === event.clientY) {
            const e = new CustomEvent('editComponent', {
                detail: {
                    component: component
                }
            });
            window.dispatchEvent(e);
            //}
        }


        return div;
    }
}
