

import {Options, Vue} from "vue-class-component";

@Options({
    emits: []
})

export default class AuthNone extends Vue{}

