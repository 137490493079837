

import {Options, Vue} from "vue-class-component";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree, DropDownSearch},
    props: {
        requestSchema: null,
        collectedData: Array,
        mapping: Object
    },
    data() {
        return {
            selectMappings: [],
            mappingOptions: [],
            mapping: Object
        }
    },
    mounted() {

        this.mapping = this.$props.mapping;
        this.mappingOptions = [];

        this.$props.collectedData?.forEach((map: any, index: number) => {

            for (const i in map) {
                this.mappingOptions.push({
                    id: index + '.' + i,
                    title: index + '.' + i
                })
            }
        })
    },
    methods: {
        pathSelected(path: string, elementId: string): void
        {
            const id = elementId.replace('app_account', '');
            this.mapping[id] = path;
        },
        toggleSelectMap(index: number) {
            this.selectMappings[index] = !this.selectMappings[index];
        },
        setSearchOption(searchOption: any) {

            const map = document.getElementById( searchOption.id) as HTMLInputElement;
            map.value = searchOption.value;
            this.selectMappings[searchOption.id] = false;
            this.$props.requestSchema[searchOption.id] = searchOption.value;

        }
    }
})
export default class OptionMapping extends Vue{}
