

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import {useRoute} from "vue-router";
import CreateAccount from "@/components/Flowbuilder/Views/Sidebar/ChannelOption/CreateAccount.vue";
import App from "@/components/Flowbuilder/Apps/App";
import AuthViewFactory from "@/components/Flowbuilder/Factory/AuthViewFactory";

@Options({
    components: {CreateAccount},

    data() {
        return {
            appAccount: null,
            app: null,
            variableValues: []
        }
    },
    methods: {

        save() {

            const configuration : any = [];

            document.querySelectorAll('input[data-configuration]').forEach((element: Element) => {
                const input = element as HTMLInputElement;

                configuration.push({
                    key: input.name,
                    value: input.value,
                });
            })

            const frmData = {
                appName: this.appAccount.appName,
                name: this.appAccount.name,
                configuration: configuration
            }
            
            ApiRequest('POST', '/app/account/' + this.appAccount.id, frmData).then(() => {
                console.log('app account saved')
            });
        }
    },
    mounted() {

        const route = useRoute()

        const authViewFactory: AuthViewFactory = new AuthViewFactory();

        ApiRequest('GET', '/app/account/' + route.params.id).then((response) => {

            this.appAccount = response;
            this.$root.header = 'Account: ' + this.appAccount.name

            const app = response;

            ApiRequest('GET', '/app?filter=' + this.appAccount.appName).then((response) => {
                this.app = new App(
                    app.appName,
                    app.name,
                    response[0].description,
                    response[0].image,
                    response[0].actions,
                    response[0].server,
                    response[0].authType,
                );

                this.appAccount.configuration.forEach((config: any) => {
                    this.variableValues[config.key] = config.value;
                })

                this.authView = authViewFactory.create(
                    this.app
                )
            });
        });
    },
    beforeMount() {
        this.$root.header = ''
    }
})

export default class AppAccountEdit extends  Vue{}

