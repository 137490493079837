import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "existing-app-account-vars" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MappingTree = _resolveComponent("MappingTree")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.requestSchema, (requestVar, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("label", null, _toDisplayString(index), 1),
          _createVNode(_component_MappingTree, {
            mapping: this.$props.collectedData,
            id: String(index),
            visible: false,
            "display-button": true,
            collectionIsSelectable: false,
            leafIsSelectable: true,
            onPathSelected: _ctx.pathSelected,
            value: typeof this.mapping !== 'undefined' ? this.mapping[index] : ''
          }, null, 8, ["mapping", "id", "onPathSelected", "value"])
        ]))
      }), 128))
    ])
  ]))
}