

import {Options, Vue} from "vue-class-component";
import CreateChannel from "@/components/Flowbuilder/Views/Sidebar/Create/CreateChannel.vue";
import CreateDecision from "@/components/Flowbuilder/Views/Sidebar/Create/CreateDecision.vue";
import CreateLoop from "@/components/Flowbuilder/Views/Sidebar/Create/CreateLoop.vue";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import CreateFunnel from "@/components/Flowbuilder/Views/Sidebar/Create/CreateFunnel.vue";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";

@Options({
    computed: {
        ComponentType() {
            return ComponentType
        }
    },
    components: {CreateFunnel, CreateLoop, CreateDecision, CreateChannel},
    props: {
        apps: Array,
        collectedData: [],
        component: null,
        parentComponent: null,
        currentCollection: null,
        position: null,
        path: {},
    },
    data() {
        return {
            componentType: ''
        }
    },
    emits: ['saveComponent'],
    methods: {
        saveComponent(component: ViewComponent) {

            const e = new CustomEvent('addComponent', {
                detail: {
                    component: component,
                    parentComponent: this.$props.parentComponent,
                    currentCollection: this.$props.currentCollection,
                    position: this.$props.position,
                }
            });

            window.dispatchEvent(e);
        },
        setComponentType(type: string) {
            this.componentType = type;
        }
    }
})

export default class Create extends Vue{}

