

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Elements/Select.vue";

@Options({
    emits: ['paramValue'],
    components: {Select},
    props: {
        appAccountId: String,
        parameters: Array,
        collectedData: Array,
    },
    data() {
        return {
            selected: {
                id: '',
                title: ''
            },
            list: []
        }
    },
    methods: {
        setDriveId(driveId: string | null): void
        {
            this.$emit('paramValue', driveId)
        },
        loadDrives() {

            let driveId = '';

            for (const i in this.$props.parameters) {
                if (this.$props.parameters[i].getName() === 'driveId') {
                    driveId = this.$props.parameters[i].getValue();
                }
            }

            ApiRequest('GET', '/google/drive/list?appAccountId=' + this.$props.appAccountId).then((response: any) => {

                this.list = [];
                this.selected.title = '';
                this.selected.id = '';

                for (const i in response) {
                    this.list.push({
                        id: response[i].id ?? '',
                        title: response[i].name,
                        description: response[i].name
                    })

                    let responseId = response[i].id === null
                        ? ''
                        : response[i].id;

                    if (responseId === driveId) {
                        this.selected.id = responseId;
                        this.selected.title = response[i].name;
                    }
                }
            });
        }
    },
    mounted() {
        this.loadDrives();
    }
})

export default class GoogleDriveDriveId extends Vue {}

