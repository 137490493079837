

import { Options, Vue } from "vue-class-component";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";

@Options({
    components: { },
    props: {
        refs: Array,
        id: Number,
        app: Object,
        component: ChannelComponent
    },
    data() {
        return {
            displayTrashCan: false,
            labelAction: ''
        }
    },
    mounted() {

        let currentAction : AppAction | null = null;

        for (let i in this.$props.component.getApp().getActions()) {
            if (this.$props.component.getApp().getActions()[i].operationId === this.$props.component.getOperationId()) {
                currentAction = this.$props.component.getApp().getActions()[i];
                break;
            }
        }

        this.labelAction = this.$props.component.getOperationId();

        if (currentAction?.getSummary() !== '') {
            this.labelAction = currentAction?.getSummary();
        }

        if (currentAction?.getDescription() !== '' ) {
            this.labelAction = currentAction?.getDescription();
        }
    },
    methods: {
        displayTrash() {
            this.displayTrashCan = true;
        },
        hideTrash() {
            this.displayTrashCan = false;
        }
    }
})
export default class Channel extends Vue {}
