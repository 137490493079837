export default class AppServer
{
    url: string;
    variables: any;

    constructor(
        url: string,
        variables: any
    ) {
        this.url = url;
        this.variables = variables;
    }

    getUrl(): string
    {
        return this.url;
    }

    getVariables(): any
    {
        return this.variables;
    }
}