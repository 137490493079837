
import {Vue} from "vue-class-component";
import GoogleDriveDriveId from "@/components/Flowbuilder/Views/Apps/GoogleDrive/Parameter/DriveId.vue";
import GoogleSheetsSpreadsheetId from "@/components/Flowbuilder/Views/Apps/GoogleSheets/Parameter/SpreadsheetId.vue";
import GoogleSheetsLookup from "@/components/Flowbuilder/Views/Apps/GoogleSheets/Parameter/Lookup.vue";
import GoogleSheetsWorksheetId from "@/components/Flowbuilder/Views/Apps/GoogleSheets/Parameter/WorksheetId.vue";
import GoogleSheetAction from "@/components/Flowbuilder/Views/Apps/GoogleSheets/Parameter/Action.vue";

export default class AppParameterViewFactory
{
    create(path: string): Vue | null
    {
        let component: any;

        switch (path) {
        case 'GoogleDrive/DriveId':
            component = GoogleDriveDriveId;
            break;
        case 'GoogleSheets/SpreadsheetId':
            component = GoogleSheetsSpreadsheetId;
            break;
        case 'GoogleSheets/WorksheetId':
            component = GoogleSheetsWorksheetId;
            break;
        case 'GoogleSheets/LookUp':
            component = GoogleSheetsLookup;
            break;

        case 'GoogleSheets/Action':
            component = GoogleSheetAction;
            break;
        default:
            return null;
        }

        return component;
    }
}
