export default class Description
{
    private _title: string;
    private _description: string;

    constructor(title: string, description: string) {
        this._title = title;
        this._description = description;
    }

    getTitle(): string {
        return this._title;
    }

    getDescription(): string {
        return this._description;
    }
}