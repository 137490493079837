import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "channel-option-parameters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParameterWrapper = _resolveComponent("ParameterWrapper")!
  const _component_MappingTree = _resolveComponent("MappingTree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$props.parameters, (param, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (typeof param.schema.format !== 'undefined')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("label", null, _toDisplayString(this.getDescription(param).getTitle()) + " " + _toDisplayString(param.required ? '*' : ''), 1),
              _createVNode(_component_ParameterWrapper, {
                id: 'param_' + param.name,
                collectedData: this.$props.collectedData,
                appAccountId: this.$props.appAccountId,
                parameters: this.$props.parameters,
                format: param.schema.format,
                value: param.getValue(),
                onParamValue: _ctx.setParamValue
              }, null, 8, ["id", "collectedData", "appAccountId", "parameters", "format", "value", "onParamValue"]),
              _createElementVNode("sub", null, _toDisplayString(this.getDescription(param).getDescription()), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("label", null, _toDisplayString(this.getDescription(param).getTitle()) + " " + _toDisplayString(param.required ? '*' : ''), 1),
              _createVNode(_component_MappingTree, {
                mapping: this.$props.collectedData,
                onPathSelected: _ctx.pathSelected,
                id: 'param_' + param.name,
                visible: false,
                displayButton: true,
                collectionIsSelectable: true,
                leafIsSelectable: true,
                value: param.value,
                onKeyUp: _ctx.keyup
              }, null, 8, ["mapping", "onPathSelected", "id", "value", "onKeyUp"]),
              _createElementVNode("sub", null, _toDisplayString(this.getDescription(param).getDescription()), 1)
            ], 64))
      ]))
    }), 128))
  ]))
}