import AppParameter from "@/components/Flowbuilder/Apps/ActionParameter";
import AppAccount from "@/models/AppAccount";
import ApiRequest from "@/api/ApiRequest";

export default class GoogleSheetRead implements MapperInterface
{
    private params: AppParameter[] = [];

    private appAccount: AppAccount | null = null;

    constructor(params: AppParameter[] = [], appAccount: AppAccount | null = null)
    {
        this.params = params;
        this.appAccount = appAccount;
    }

    async map(schema: any, mappingName: string): Promise<any>
    {
        let mapped:any = {};

        const queryString = '?appAccountId='
            + this.appAccount?.getId()
            + '&worksheetId=' + this.findValue('worksheetId')
            + '&fileId=' + this.findValue('spreadsheetId');

        try {
            mapped = await ApiRequest('GET', '/google/spreadsheet/data' + queryString);
        } catch (e) {
            return [];
        }

        const collection : any= [];

        for (const colIndex in mapped) {
            collection[colIndex] = [];

            for (const rowIndex in mapped[colIndex]) {

                const keyIndex = Object.keys(mapped[colIndex][rowIndex])[0];
                const label = rowIndex + '(' + mapped[colIndex][rowIndex][keyIndex] + ')';
                collection[colIndex][label] = mapped[colIndex][rowIndex][keyIndex];
            }

        }

        return {source: mappingName, mapping: collection};
    }

    findValue(key: string): string
    {
        let value = '';

        this.params.forEach((param: AppParameter) => {
            if (param.getName() === key) {
                value = param.getValue()
            }
        })

        return value;
    }
}
