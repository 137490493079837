

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";

@Options({
    components: {Select},
    props: {
        // actions parsed form yaml file
        // not AppActions[]
        actions: [],
        component: null
    },
    emits: ['operationIdSelected'],
    data() {
        return {
            selectedApp: null,
            appAccounts: [],
            selectActionOptions: [],
            selectedOption: {},
            operationId: '',
        }
    },
    methods: {
        setOperationId(operationId: string) {
            this.$emit('operationIdSelected', operationId)
        }
    },
    mounted() {
        if (typeof this.$props.component !== 'undefined') {

            const channel : ChannelComponent = this.$props.component as ChannelComponent;

            let title = '';

            this.$props.actions.forEach((action: any) => {
                if (action.id === channel.getOperationId()) {
                    title = action.title;
                }
            })

            this.selectedOption = {
                id: channel.getOperationId(),
                title: title,
            }
        }
    }
})

export default class OptionAction extends Vue{}
