export default class Auth
{
    auth: any;

    constructor(
        auth: any
    ) {
        this.auth = auth;
    }

    getType(): string | null
    {
        if (this.auth === null) {
            return null;
        }

        return Object.keys(this.auth)[0];
    }

    isOauth2(): boolean {
        return this.auth !== null && this.getType()?.toLowerCase() === 'oauth2';
    }

    getOauth2Client(): string
    {
        if (this.auth === null || this.getType()?.toLowerCase() !== 'oauth2') {
            return '';
        }

        const auth = this.auth[Object.keys(this.auth)[0]];
        const clientProperty = 'x-client';

        return auth.hasOwnProperty(clientProperty)
            ? auth[clientProperty]
            : '';
    }

    getOauth2FlowType(): string
    {
        if (this.auth === null || this.getType()?.toLowerCase() !== 'oauth2') {
            return '';
        }

        const auth = this.auth[Object.keys(this.auth)[0]];
        const flowProperty = 'flows';

        return auth.hasOwnProperty(flowProperty)
            ? Object.keys(auth[flowProperty])[0]
            : '';
    }
}