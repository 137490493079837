import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(this.$props.app.getAuth().auth.ApiKeyAuth.name), 1),
    _withDirectives(_createElementVNode("input", {
      "data-configuration": "1",
      class: "form-control",
      name: this.$props.app.getAuth().auth.ApiKeyAuth.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.values[this.$props.app.getAuth().auth.ApiKeyAuth.name]) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, this.values[this.$props.app.getAuth().auth.ApiKeyAuth.name]]
    ])
  ]))
}