

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";

@Options({
    components: {DropDownSearch, Select},
    emits: ['saveRule'],
    props: {
        collectedData: Array,
        rules: Array
    },
    data() {
        return {
            tab: {
                active: 'default'
            },
            mappingOptions: [],
            field: '',
            method: ''
        }
    },
    methods:{

        setComparison(comparison: string): void {
            this.comparison = comparison;
        },
        toggleSelectMap(index: number): void {
            this.selectMappings[index] = !this.selectMappings[index];
        },
        setField(field: any): void {
            this.field = field;
        },
        saveRule(): void {
            this.$emit('saveRule', this.field, this.method)
        },
        removeRule(index: number): void
        {
            this.$emit('removeRule', index)
        }
    },
    mounted() {

        this.mappingOptions = [];
        this.$props.collectedData?.forEach((map: any, index: number) => {

            for (const i in map) {
                this.mappingOptions.push({
                    id: index + '.' + i,
                    title: index + '.' + i
                })
            }
        })
    }
})
export default class DecisionRuleDefault extends Vue{}
