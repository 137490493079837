import ObjectMapper from "@/components/Flowbuilder/Mapping/Concrete/Default/ObjectMapper";
import GoogleSheetRead from "@/components/Flowbuilder/Mapping/Concrete/GoogleSheet/GoogleSheetRead";
import AppParameter from "@/components/Flowbuilder/Apps/ActionParameter";
import AppAccount from "@/models/AppAccount";

export default class MapperFactory
{
    private params: AppParameter[] = [];

    private appAccount: AppAccount | null = null;

    constructor(params: AppParameter[] = [], appAccount: AppAccount | null = null)
    {
        this.params = params;
        this.appAccount = appAccount;
    }

    create(schema: any): MapperInterface | null
    {
        if (typeof schema === 'undefined') {
            return null;
        }

        if (typeof schema.type === 'undefined') {
            return null;
        }

        if (typeof schema.format !== 'undefined') {
            switch (schema.format) {
            case 'GoogleSheets/Read':
                return new GoogleSheetRead(this.params, this.appAccount);
            default:
                return null;
            }
        }

        return new ObjectMapper();
    }
}
