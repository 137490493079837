

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import DecisionTrueComponent from "@/components/Flowbuilder/Builder/Components/DecisionTrueComponent";
import DecisionFalseComponent from "@/components/Flowbuilder/Builder/Components/DecisionFalseComponent";
import DecisionComponent from "@/components/Flowbuilder/Builder/Components/DecisionComponent";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";
import DecisionRuleDefault from "@/components/Flowbuilder/Views/Sidebar/ChannelOption/DecisionRuleDefault.vue";
import DecisionRuleCustom from "@/components/Flowbuilder/Views/Sidebar/ChannelOption/DecisionRuleCustom.vue";

@Options({
    components: {DecisionRuleCustom, DecisionRuleDefault, DropDownSearch, Select},
    props: {
        collectedData: Array,
        rules: Array,
        component: null
    },
    emits: ['saveComponent'],
    data() {
        return {
            tab: {
                active: 'default'
            },
            rules: {
                default: [],
                custom: []
            },
            mappingOptions: [],
            comparisonOptions: [
                {id: 'eq', title: 'equals'},
                {id: 'ne', title: 'not equals'},
                {id: 'lt', title: 'lesser than'},
                {id: 'gt', title: 'greater than'},
            ],
            comparison: '',
            field: '',
            value: ''

        }
    },
    mounted() {
        if (typeof this.$props.component !== 'undefined') {
            const decision : DecisionComponent = this.$props.component as DecisionComponent;
            this.rules.default = decision.getRules().default;
            this.rules.custom = decision.getRules().custom;
        }
    },
    methods:{
        removeDefaultRule(index: number): void
        {
            const rules: any[] = [];

            for (let i = 0; i < this.rules.default.length; i++) {
                if (i !== index) {
                    rules.push(this.rules.default[i])
                }
            }

            this.rules.default = rules;
        },
        removeCustomRule(index: number): void
        {
            const rules: any[] = [];

            for (let i = 0; i < this.rules.custom.length; i++) {
                if (i !== index) {
                    rules.push(this.rules.custom[i])
                }
            }

            this.rules.custom = rules;
        },
        tabActive(element: MouseEvent) {
            const targetElement: HTMLElement = element.target as HTMLElement;
            this.tab.active = targetElement.getAttribute('data-tab');
        },
        setComparison(comparison: string): void {
            this.comparison = comparison;
        },
        toggleSelectMap(index: number): void {
            this.selectMappings[index] = !this.selectMappings[index];
        },
        setField(field: any): void {
            this.field = field;
        },
        saveDefaultRule(field: string, comparison: string, value: string): void {
            this.rules.default.push({
                field: field,
                comparison: comparison,
                value: value
            })
        },
        saveCustomRule(field: string, method: string): void {
            this.rules.custom.push({
                field: field,
                method: method
            })
        },
        saveDecision(): void
        {
            if (typeof this.$props.component !== 'undefined') {

                const decision : DecisionComponent = this.$props.component as DecisionComponent;
                decision.setRules(this.rules);

                const e = new CustomEvent('updateComponent', {
                    detail: {
                        component: decision,
                        parentComponent: this.$props.parentComponent,
                        currentCollection: this.$props.currentCollection,
                    }
                });

                window.dispatchEvent(e);

            } else {
                const componentTrue: DecisionTrueComponent = new DecisionTrueComponent();
                const viewTrue: ViewComponent = new ViewComponent(ComponentType.DECISION_TRUE, null, componentTrue, 0, 0)

                const componentFalse: DecisionFalseComponent = new DecisionFalseComponent();
                const viewFalse: ViewComponent = new ViewComponent(ComponentType.DECISION_FALSE, null, componentFalse, 0, 0);

                const decision: DecisionComponent = new DecisionComponent(null, this.rules, [], []);
                const view: ViewComponent = new ViewComponent(ComponentType.DECISION, null, decision, 0, 0, [viewTrue, viewFalse])

                this.$emit('saveComponent', view)
            }
        }
    }
})
export default class CreateDecision extends Vue{}

