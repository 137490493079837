
import ComponentDraw from "@/components/Flowbuilder/PlumbDrawer/ComponentDraw";
import {App} from "vue";
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import LineDraw from "@/components/Flowbuilder/Line/LineDraw";
import ContainerHelper from "@/components/Flowbuilder/Builder/Container/ContainerHelper";
import Plus from "@/components/Flowbuilder/Builder/Plus";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";

export default class FlowBuilder {

    static readonly DEFAULT_DISTANCE_Y = 140;

    static readonly DEFAULT_DISTANCE_DECISION_FALSE_X = 260;

    static readonly DEFAULT_DISTANCE_DECISION_TRUE_X = 130;

    static readonly DEFAULT_DISTANCE_LOOP_X = 400;

    helper: ComponentDraw;

    instance :BrowserJsPlumbInstance;

    collectionFlattened: ViewComponent[] = [];

    workflowId: string;

    useCustomPositions = false;

    constructor(instance :BrowserJsPlumbInstance, workflowId: string) {
        this.instance = instance;
        this.helper = new ComponentDraw(this.instance)
        this.workflowId = workflowId;
    }

    loop(
        collection: ViewComponent[],
        startOffsetX = 0,
        startOffsetY = 0,
        parentComponent: ViewComponent | null = null,
        x: string[] = [],
        depth = 0,
        decision : null | string = null
    ) {


        const containerHelper: ContainerHelper = new ContainerHelper(this.instance, this.workflowId);
        const jsPlumbContainer: HTMLElement = document.getElementById('jsplumb-canvas') as HTMLElement;
        const path = x;
        let lineDraw : LineDraw;

        for (let i = 0; i < collection.length; i++) {
            lineDraw = new LineDraw(this.instance, collection, parentComponent, i)
            const id:string  = (Date.now() + Math.random()) + (':' + collection[i].getType());

            path.push(id);

            startOffsetY = containerHelper.calculateOffsetY(collection[i].getType(), startOffsetY);
            startOffsetX = containerHelper.calculateOffsetX(collection[i].getType(), startOffsetX);

            // activates custom positioning, looks weird with insert channel in between
            // other channels, technically it works good
            if (this.useCustomPositions) {
                startOffsetX = collection[i].getPositionX() === 0
                    ? startOffsetX
                    : collection[i].getPositionX();

                startOffsetY = collection[i].getPositionY() === 0
                    ? startOffsetY
                    : collection[i].getPositionY();
            }

            this.collectionFlattened.push(collection[i]);

            collection[i].setId(
                id
            );

            collection[i].setPath(this.clone(path));

            collection[i].setPositionY(
                startOffsetY
            );

            collection[i].setPositionX(
                startOffsetX
            );

            // create container and view
            collection[i].setHtmlContainer(
                containerHelper.createContainer(
                    collection[i],
                    '',
                    i
                )
            );

            jsPlumbContainer.appendChild(
                collection[i].getHtmlContainer()
            );

            const view: App = containerHelper.createView(collection[i])

            view.mount(
                collection[i].getHtmlContainer()
            );

            if (collection[i].getType() === ComponentType.DECISION_FALSE ) {

                path.pop();
                path.pop();

                const id2 = (Date.now() + Math.random()) + (':' + collection[i].getType());
                path.push(id2);
                collection[i].setId(id2);

                this.collectionFlattened.push(collection[i]);
                collection[i].setPath(path)
            }

            // iterate through childNodes of component
            if (collection[i].getChildNodes().length > 0 || collection[i].getType() === ComponentType.LOOP || collection[i].getType() === ComponentType.DECISION_FALSE || collection[i].getType() === ComponentType.DECISION_TRUE) {

                let nextOffsetX = collection[i].getPositionX();
                let nextOffsetY = collection[i].getPositionY();

                if (collection[i].getType() === ComponentType.LOOP) {
                    nextOffsetX -= FlowBuilder.DEFAULT_DISTANCE_LOOP_X;
                    nextOffsetY += 64;
                }

                if (collection[i].getType() === ComponentType.DECISION_TRUE || collection[i].getType() === ComponentType.DECISION_FALSE) {
                    nextOffsetY -= 30;
                    decision = collection[i].getType();
                }

                this.loop(collection[i].getChildNodes(), nextOffsetX, nextOffsetY, collection[i], this.clone(path),  (depth + 1 + i), decision);
            }

            // lines
            if (i === 0 && parentComponent?.getType() === ComponentType.LOOP) {
                // draw starting line of loop component
                lineDraw.startloop(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer());
            }

            if ((parentComponent?.getType() === ComponentType.DECISION_FALSE || parentComponent?.getType() === ComponentType.DECISION_TRUE) && collection[i]?.getType() === ComponentType.LOOP && i === 0) {
                // draw starting line of loop component
                lineDraw.line(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer());
            }

            if (i > 0 && parentComponent?.getType() !== ComponentType.DECISION) {
                lineDraw.line(collection[i - 1].getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer());
            }

            if (collection[i].getType() === ComponentType.DECISION_FALSE && parentComponent?.getType() === ComponentType.DECISION) {
                lineDraw.decisionFalse(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer() as HTMLElement);
            }

            if (collection[i].getType() === ComponentType.DECISION_TRUE && parentComponent?.getType() === ComponentType.DECISION) {
                lineDraw.decisionTrue(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer() as HTMLElement);
            }

            // in the decision false/true lane, connects only the first component to the parent
            if ((parentComponent?.getType() === ComponentType.DECISION_FALSE || parentComponent?.getType() === ComponentType.DECISION_TRUE) && (collection[i].getType() === ComponentType.CHANNEL || collection[i].getType() === ComponentType.FUNNEL) && i === 0) {
                lineDraw.line(parentComponent.getHtmlContainer() as HTMLElement, collection[i].getHtmlContainer() as HTMLElement);
            }

            // loop breakers
            if (collection[i].getType() === ComponentType.DECISION) {
                // prevent creating a plus in the wrong lane by stopping all iteration
                // further iteration is done in the lanes
                return;
            }
        }

        const lastComponent : ViewComponent | null = collection.length === 0 ? null : collection[collection.length -1];

        lineDraw = new LineDraw(this.instance, collection, parentComponent, collection.length)

        if (parentComponent?.getType() !== ComponentType.DECISION ) {

            if (parentComponent?.getType() === ComponentType.LOOP && lastComponent === null) {

                const plus = (new Plus(collection, startOffsetX + 14, startOffsetY + 40, '', parentComponent)).create();
                jsPlumbContainer.appendChild(plus);

                lineDraw.startloop(parentComponent.getHtmlContainer() as HTMLElement, plus);

            } else if ((parentComponent?.getType() === ComponentType.DECISION_FALSE || parentComponent?.getType() === ComponentType.DECISION_TRUE) && collection.length === 0) {

                const plus = (new Plus(collection, startOffsetX, startOffsetY + FlowBuilder.DEFAULT_DISTANCE_Y - 60, '', parentComponent)).create();
                jsPlumbContainer.appendChild(plus);

                lineDraw.line(parentComponent.getHtmlContainer() as HTMLElement, plus);

            } else if (collection.length > 0) {

                const plus = (new Plus(collection, startOffsetX, startOffsetY, '', parentComponent)).create();
                jsPlumbContainer.appendChild(plus);

                lineDraw.line(collection[collection.length - 1].getHtmlContainer() as HTMLElement, plus);
            }
        }
    }

    clone(path: string[]) : string[]
    {
        return JSON.parse(
            JSON.stringify(path)
        );
    }
}