import {createRouter, createWebHistory} from "vue-router";

import Home from "@/views/Home/Home.vue";
import PasswordReset from "@/views/Account/PasswordReset.vue";
import PasswordResetSend from "@/views/Account/PasswordResetSend.vue";
import Login from "@/views/Account/Login.vue";
import Register from "@/views/Account/Register.vue";
import RegisterConfirm from "@/views/Account/RegisterConfirm.vue";
import RegisterResend from "@/views/Account/RegisterResend.vue";
import Account from "@/views/Account/Account.vue";
import AppCreate from "@/views/App/AppCreate.vue";
import WorkflowOverview from "@/views/Workflow/WorkflowOverview.vue";
import WorkflowEditor from "@/views/Workflow/WorkflowEditor.vue";
import WorkflowEdit from "@/views/Workflow/WorkflowEdit.vue";
import WorkflowView from "@/views/Workflow/WorkflowView.vue";
import AppOverview from "@/views/App/AppOverview.vue";
import AppAccountOverview from "@/views/App/AppAccountOverview.vue";
import createStore from "../store";
import AppAccountEdit from "@/views/App/AppAccountEdit.vue";
import Upgrade from "@/views/Account/Upgrade.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    }
    /* Account */
    , {
        path: '/login',
        name: 'Login',
        component: Login
    }, {
        path: '/register',
        name: 'Register',
        component: Register
    }, {
        path: '/register/confirm/:signature',
        name: 'RegisterConfirm',
        component: RegisterConfirm
    }, {
        path: '/register/resend',
        name: 'RegisterResend',
        component: RegisterResend
    }, {
        path: '/password/reset',
        name: 'PasswordReset',
        component: PasswordReset
    }, {
        path: '/password/reset/send',
        name: 'PasswordResetSend',
        component: PasswordResetSend
    }, {
        path: '/apps',
        name: 'AppOverview',
        component: AppOverview
    }, {
        path: '/app/create',
        name: 'AppCreate',
        component: AppCreate
    }, {
        path: '/app/:appName',
        name: 'AppAccountOverview',
        component: AppAccountOverview,
    }, {
        path: '/app/:appName/:id',
        name: 'AppAccountEdit',
        component: AppAccountEdit,
    }, {
        path: '/account',
        name: 'Account',
        component: Account
    }, {
        path: '/workflow',
        name: 'WorkflowOverview',
        component: WorkflowOverview
    }, {
        path: '/workflow/:id/editor',
        name: 'WorkflowEditor',
        component: WorkflowEditor
    },{
        path: '/workflow/:id/edit',
        name: 'WorkflowEdit',
        component: WorkflowEdit
    }, {
        path: '/workflow/:id',
        name: 'WorkflowView',
        component: WorkflowView
    }, {
        path: '/upgrade',
        name: 'Upgrade',
        component: Upgrade
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: 'selectedMenuItem'
});

router.beforeEach(function (to, from, next) {

    if (
        to.name === 'Login'
        || to.name === 'PasswordReset'
        || to.name === 'PasswordResetSend'
        || to.name === 'Register'
        || to.name === 'RegisterConfirm'
        || to.name === 'RegisterResend'
    ) {
        next();
        return;
    }

    const user = createStore.getters.getSignedInUser;

    if (user === null || typeof user.token === 'undefined') {

        createStore.dispatch('logoutSignedInUser').then(() => {
            next({name: 'Login'});
        })

        return;
    }

    const currentTimestamp = Math.round(new Date().getTime() / 1000);

    if (currentTimestamp >= user.token.expiresAt) {
        createStore.dispatch('logoutSignedInUser').then(() => {
            next({name: 'Login'});
        })

        return;
    }

    next();
});

export default router;
