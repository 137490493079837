

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import Select from "@/components/Elements/Select.vue";

@Options({
    components: {Select, MappingTree},
    props: {
        appAccountId: String,
        parameters: Array,
        collectedData: Array,
    },
    data() {
        return {
            list: [
                {id: 'insert', title: 'Insert new row', description: 'Insert new row at the bottom of the spreadsheet'},
                {id: 'single_update', title: 'Update one cell', description: 'Update cell'},
                {id: 'batch_update', title: 'Update one or more cells', description: 'Update cells'}
            ],
            selected: {id: '', title: '', description: ''}
        }
    },
    mounted() {
        this.selected.title = '';
        this.selected.id = '';

        let action: string | null = null;

        for(const i in this.$props.parameters) {
            //if (this.$props.parameters[i].getName() === 'action') {
            action = this.$props.parameters[i].getValue();
            //}
        }

        if (action !== null) {
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id === action) {
                    this.selected = this.list[i];
                }
            }
        }
    },
    methods: {
        setAction(action: string) {
            this.$emit('paramValue', action)
        },
    }
})

export default class GoogleSheetAction extends Vue{}


