import Component from "@/components/Flowbuilder/Builder/Components/Component";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";

export default class LoopComponent extends Component
{
    id: string | null;

    field: string;

    childNodes: ViewComponent[] = [];

    constructor(id: string | null, field: string, childNodes: ViewComponent[]) {
        super('');
        this.id = id;
        this.field = field;
        this.childNodes = childNodes;
    }

    getField(): string
    {
        return this.field;
    }

    setField(field: string): void
    {
        this.field = field;
    }

    getChildNodes(): ViewComponent[]
    {
        return this.childNodes;
    }
}
