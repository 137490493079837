import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mapping-container" }
const _hoisted_2 = { class: "search-container" }
const _hoisted_3 = ["name"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "data-mapping": "1",
        name: this.id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.value) = $event)),
        type: "text",
        class: "form-control search-box",
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (this.search && this.search(...args))),
        placeholder: "type or pick"
      }, null, 40, _hoisted_3), [
        [_vModelText, this.value]
      ]),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleVisibility && _ctx.toggleVisibility(...args))),
        class: _normalizeClass('map-button ' + (!this.$props.displayButton ? 'hide' : '') + ' '  + (this.visible ? 'active' : ''))
      }, "map", 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass('mapping-tree ' + (this.visible ? 'active' : 'hide')),
      id: this.$props.id
    }, null, 10, _hoisted_4)
  ]))
}