

import {Options, Vue} from "vue-class-component";
import App from "@/components/Flowbuilder/Apps/App";

@Options({
    props: {
        app:App,
        configuration: Array
    },
    data() {
        return {
            values: []
        }
    },
    mounted() {
        if (typeof this.$props.configuration !== 'undefined') {
            this.$props.configuration.forEach((config: any) => {
                this.values[config.key] = config.value
            });
        }
    }
})
export default class BearerKey extends Vue{}

