export default class ObjectMapper implements MapperInterface
{
    map(schema: any, mappingName: string): any
    {
        const mapped:any = {};

        for (const i in schema.properties) {

            if (typeof schema.properties[i].type === 'undefined') {
                continue;
            }
            
            if (schema.properties[i].type === 'string' || schema.properties[i].type === 'integer') {
                mapped[i] = i;
            } else if (schema.properties[i].type === 'array') {
                mapped[i + '[]'] = this.map(schema.properties[i].items, mappingName).mapping;
            } else {
                mapped[i] = this.map(schema.properties[i], mappingName).mapping
            }
        }

        return {source: mappingName, mapping: mapped};
    }
}
