

import {Options, Vue} from "vue-class-component";
import createStore from "@/store";

@Options({
    props: {
        user: Object,
        active: String
    },
    methods: {
        logout() {
            createStore.dispatch('logoutSignedInUser').then(() => {
                this.$router.push({name: 'Login'})
            })
        },
        upgrade() {
            this.$router.push({name: 'Upgrade'})
        }
    }
})

export default class Menu extends  Vue{}

