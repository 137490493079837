import AppParameter from "@/components/Flowbuilder/Apps/ActionParameter";

export default class AppParameterMerge
{
    merge(params: any, appParams: AppParameter[] = []): AppParameter[]
    {
        appParams.forEach((appParam: AppParameter) => {
            for (const i in params) {
                if (params[i].name === appParam.getName()) {
                    appParam.setValue(params[i].value);
                }
            }
        })

        return appParams;
    }
}