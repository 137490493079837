import MapperFactory from "@/components/Flowbuilder/Mapping/Factory/MapperFactory";
import AppParameter from "@/components/Flowbuilder/Apps/ActionParameter";
import AppAccount from "@/models/AppAccount";

export default class Mapper {
    flatten(mapping: any): any {

        const toReturn: { [key: string]: string } = {};

        for (const i in mapping) {
            if (!mapping.hasOwnProperty(i)) continue;

            if ((typeof mapping[i]) == 'object' && mapping[i] !== null) {
                const flatObject = this.flatten(mapping[i]);
                for (const x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) {
                        continue;
                    }

                    toReturn[i + '.' + x] = '';
                }
            } else {
                toReturn[i] = '';
            }
        }

        return toReturn;
    }



    async map(schema: any, mappingName: string, params: AppParameter[] = [], appAccount: AppAccount | null = null): Promise<Array<any>> {

        const factory : MapperFactory = new MapperFactory(params, appAccount);
        const mapper = factory.create(schema.schema);

        if (mapper === null) {
            return [];
        }

        return mapper.map(
            schema.schema,
            mappingName
        );
    }
}
