import Channel from "@/components/Flowbuilder/Views/Types/Channel.vue";
import Loop from "@/components/Flowbuilder/Views/Types/Loop.vue";
import Decision from "@/components/Flowbuilder/Views/Types/Decision.vue";
import DecisionLane from "@/components/Flowbuilder/Views/Types/DecisionLane.vue";
import {Vue} from "vue-class-component";
import Trigger from "@/components/Flowbuilder/Views/Types/Trigger.vue";
import TriggerEdit from "@/components/Flowbuilder/Views/Sidebar/TriggerEdit.vue";
import Endpoint from "@/components/Flowbuilder/Views/Types/Endpoint.vue";
import CreateDecision from "@/components/Flowbuilder/Views/Sidebar/Create/CreateDecision.vue";
import CreateChannel from "@/components/Flowbuilder/Views/Sidebar/Create/CreateChannel.vue";
import CreateLoop from "@/components/Flowbuilder/Views/Sidebar/Create/CreateLoop.vue";
import Funnel from "@/components/Flowbuilder/Views/Types/Funnel.vue";
import CreateFunnel from "@/components/Flowbuilder/Views/Sidebar/Create/CreateFunnel.vue";

export default class ComponentViewFactory
{
    create(type: string): Vue
    {
        let component: any;

        switch (type) {
        case 'trigger':
            component = Trigger;
            break;
        case 'channel':
            component = Channel;
            break;
        case 'funnel':
            component = Funnel;
            break;
        case 'loop':
            component = Loop;
            break;
        case 'decision':
            component = Decision;
            break;
        case 'endpoint':
            component = Endpoint;
            break;
        case 'decisionTrue':
        case 'decisionFalse':
            component = DecisionLane;
            break;
        default:
            throw new Error('unknown component ' + type)
        }

        return component;
    }

    edit(type: string): Vue
    {
        let component: any;

        switch (type) {
        case 'trigger':
            component = TriggerEdit;
            break;
        case 'funnel':
            component = CreateFunnel;
            break;
        case 'loop':
            component = CreateLoop;
            break;
        case 'decision':
            component = CreateDecision;
            break;
        case 'channel':
            component = CreateChannel
            break;
        default:
            throw new Error('unknown component' + type)
        }

        return component;
    }
}