
import ComponentDraw from "@/components/Flowbuilder/PlumbDrawer/ComponentDraw";
import {BrowserJsPlumbInstance} from "@jsplumb/browser-ui";
import {Vue} from "vue-class-component";
import ComponentViewFactory from "@/components/Flowbuilder/Factory/ComponentViewFactory";
import {App, createApp} from "vue";
import FlowBuilder from "@/components/Flowbuilder/Builder/FlowBuilder";
import { createI18n } from 'vue-i18n'
import nl from "../../../../../locales/nl.json";
import en from "../../../../../locales/en.json";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";
import TriggerComponent from "@/components/Flowbuilder/Builder/Components/TriggerComponent";
import CmApp from "@/components/Flowbuilder/Apps/App";
import AppCollection from "@/components/Flowbuilder/Apps/AppCollection";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import Mapper from "@/components/Flowbuilder/Mapping/Mapper";
import AppParameterMerge from "@/components/Flowbuilder/Builder/Helper/AppParameterMerge";
import FunnelComponent from "@/components/Flowbuilder/Builder/Components/FunnelComponent";
import ApiRequest from "@/api/ApiRequest";

export default class ContainerHelper
{
    private instance :BrowserJsPlumbInstance;

    private workflowId: string;

    constructor(instance :BrowserJsPlumbInstance, workflowId: string) {
        this.instance = instance;
        this.workflowId = workflowId;
    }

    createContainer(component: ViewComponent, label: string, position: number): HTMLElement
    {
        const drawer: ComponentDraw = new ComponentDraw(this.instance);

        const container: HTMLElement = drawer.draw(
            component.getPositionX(),
            component.getPositionY(),
            component,
            position
        );

        const id: number = Math.floor(Math.random() * (99999 - 10000 + 1) + 99999);

        container.setAttribute(
            'component-position',
            String(position)
        );

        container.setAttribute(
            'component-type',
            component.getType()
        );

        container.setAttribute(
            'component-id',
            String(id)
        );

        container.setAttribute(
            'component-label',
            label
        );

        return container;
    }

    createView(component: ViewComponent): App {

        const factory: ComponentViewFactory = new ComponentViewFactory();

        const view : Vue = factory.create(
            component.getType()
        )

        const i18n = createI18n({
            locale: "en",
            globalInjection: true,
            fallbackLocale: "en",
            messages: { nl, en },
            legacy: false
        });

        return createApp({ extends: view }, {
            id: 'ref',
            component: component.getComponent()
        }).use(i18n);
    }

    calculateOffsetY(type: string, startOffsetY: number): number
    {
        let nextOffsetY: number = startOffsetY;

        switch(type) {
        case ComponentType.DECISION_FALSE:
            nextOffsetY -= 0;
            break;
        case ComponentType.DECISION_TRUE:
            nextOffsetY += 80;
            break;
        default:
            nextOffsetY += FlowBuilder.DEFAULT_DISTANCE_Y;
            break;
        }

        return nextOffsetY;
    }

    calculateOffsetX(type: string, startOffsetX: number): number
    {
        let nextOffsetX: number = startOffsetX;

        switch(type) {
        case ComponentType.DECISION_FALSE:
            nextOffsetX -= FlowBuilder.DEFAULT_DISTANCE_DECISION_FALSE_X;
            break;
        case ComponentType.DECISION_TRUE:
            nextOffsetX += FlowBuilder.DEFAULT_DISTANCE_DECISION_TRUE_X;
            break;

        }

        return nextOffsetX;
    }

    removeComponent(view: ViewComponent, collection : ViewComponent[]): void
    {

        for (let e = 0; e < collection.length; e++) {

            const i = e;

            if (collection[i].getId() === view.getId()) {
                collection.splice(i,1);
            }

            if (typeof collection[i] !== 'undefined' && collection[i].getChildNodes().length > 0) {
                collection[i].setChildNodes(
                    this.remove(view, collection[i].getChildNodes())
                );
            }
        }
    }

    remove(view: ViewComponent, collection : ViewComponent[]): ViewComponent[]
    {
        let add = true;
        const n : ViewComponent[] = [];

        for (let e = 0; e < collection.length; e++) {

            const i = e;

            if (collection[i].getId() === view.getId()) {
                add = false;
            }

            if (add) {
                n.push(collection[i]);
            }

            if (typeof collection[i] !== 'undefined' && collection[i].getChildNodes().length > 0) {
                collection[i].setChildNodes(
                    this.remove(view, collection[i].getChildNodes())
                );
            }
        }

        return n;
    }

    async translateFunnel(collection : ViewComponent[], funnel: FunnelComponent, index: number): Promise<any>
    {
        const splits = funnel.getField().split('.');

        if (splits.length === 0) {
            console.log('not a mapping, abandon ship!');
            return []
        }

        const channel: ChannelComponent = collection[parseInt(splits[0])].getComponent() as ChannelComponent;

        const queryString = '?field='
            + funnel.getField()
            + '&value=' + funnel.getValue()
            + '&comparison=' + funnel.getComparison()
            + '&operationId=' + channel.getOperationId();

        let mapped: any = [];

        try {
            mapped = await ApiRequest('GET', '/workflow/' + this.workflowId + '/funnel/data' + queryString);
        } catch (e) {
        }


        return {
            source: 'Funnel',
            mapping: mapped
        }
    }

    async translate(apps: AppCollection, channel: ChannelComponent): Promise<any>
    {
        let cAction : AppAction = new AppAction('');

        apps.getApps().forEach((app: CmApp) => {
            if (app.getSlug() === channel.getApp().getSlug()) {
                app.getActions().forEach(async (action: AppAction) => {
                    if (action.getOperationId() === channel.getOperationId()) {
                        cAction = action;
                    }
                })
            }
        })

        if (cAction === undefined) {
            return [];
        }

        const appParams = (new AppParameterMerge).merge(
            channel.getParameters(),
            cAction.getParameters()
        );

        if (typeof cAction?.getResponses() === 'undefined' || cAction?.getResponses().length === 0) {
            console.log('no response schema present for ' + channel.getOperationId())
            return [];
        }

        const response: any = cAction.getResponses();
        const responseStatusCodeKey: string[] = Object.keys(response);
        const responseContentTypeKey: string[] = Object.keys(response[responseStatusCodeKey[0]].content);
        const mapping: Mapper = new Mapper();

        return mapping.map(
            response[responseStatusCodeKey[0]].content[responseContentTypeKey[0]],
            channel.getApp().getTitle(),
            appParams,
            channel.getAppAccount()
        );
    }

    mappingObjects(path: string[], collection : ViewComponent[], apps: AppCollection, action: string): Promise<any>
    {
        let tmp : ViewComponent | null;
        const promises = [];
        const pathLength = action === 'create' ? path.length : path.length -1;

        for (let i = 0; i < pathLength; i++) {

            tmp = this.findView(path[i], collection);

            if (tmp !== null && (tmp.getType() === ComponentType.CHANNEL || tmp.getType() === ComponentType.FUNNEL)) {

                if (tmp.getType() === ComponentType.CHANNEL) {
                    const channel: ChannelComponent = tmp.getComponent() as ChannelComponent;

                    promises.push(
                        this.translate(
                            apps,
                            channel
                        )
                    );
                }

                if (tmp.getType() === ComponentType.FUNNEL) {

                    const funnel: FunnelComponent = tmp.getComponent() as FunnelComponent;

                    promises.push(
                        this.translateFunnel(
                            collection,
                            funnel,
                            i
                        )
                    );
                }
            }
        }

        return Promise.all(promises);
    }

    findView(path: string, collection: ViewComponent[]): any
    {
        for (const i in collection) {
            if (collection[i].getId() === path) {
                return collection[i];
            }

            if (collection[i].getChildNodes().length > 0) {
                this.findView(path, collection[i].getChildNodes());
            }
        }

        return null;
    }

    async mapping(path: string[], collection : ViewComponent[], apps: AppCollection, action: string): Promise<any>
    {
        const map = [];

        const trigger : TriggerComponent = collection[0].getComponent() as TriggerComponent;

        const t = trigger.getType() === 'webhook'
            ? trigger.getParameters().request
            : [];

        map.push({source: 'webhook', mapping : t});

        const mapping = await this.mappingObjects(path, collection, apps, action);

        return map.concat(
            mapping
        )

    }
}