import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Select, {
      title: '',
      id: 'driveId',
      ref: "selectFileId",
      selected: this.selected,
      options: this.list,
      onOptionSelected: _ctx.setAction
    }, null, 8, ["selected", "options", "onOptionSelected"])
  ]))
}