

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import ParameterWrapper from "@/components/Flowbuilder/Views/Apps/ParameterWrapper.vue";
import Description from "@/components/Flowbuilder/Helper/Description";
import DescriptionHelper from "@/components/Flowbuilder/Helper/DescriptionHelper";

@Options({
    components: {ParameterWrapper, MappingTree},
    emits: ['saveParameters'],
    props: {
        parameters: Array,
        collectedData: Array,
        appAccountId: String
    },
    data() {
        return {
            parameters: []
        }
    },
    methods: {
        getDescription(param: any): Description
        {
            return (new DescriptionHelper()).parse(param.description, param.name);
        },
        setParamValue(value: any): void
        {
            this.pathSelected(value.value, value.id);
        },
        keyup(value: string, id: string) {
            const pid: string  = id.replace('param_', '')

            this.$props.parameters.forEach((param: any) => {
                if (param.name === pid) {
                    param.value = value;
                }
            });
        },
        saveParameters() : void
        {
            const params: any[] = [];

            this.$props.parameters.forEach((param: any) => {
                params[param.name] = param.value;
            })

            this.$emit('saveParameters', params)
        },
        pathSelected(path: string, id: string) {
            const pid: string  = id.replace('param_', '')

            this.$props.parameters.forEach((param: any) => {
                if (param.name === pid) {
                    param.value = path;
                }
            });
        }
    }
})


export default class OptionParameters extends Vue{}
