import AppAction from "@/components/Flowbuilder/Apps/AppAction";
import AppServer from "@/components/Flowbuilder/Apps/AppServer";
import Auth from "@/components/Flowbuilder/Apps/Auth";

export default class App
{
    private slug: string;
    private title: string;
    private description: string;
    private image: string;
    private actions: AppAction[] = [];
    private servers: AppServer[] = [];
    private auth: Auth;

    constructor(
        slug: string,
        title: string,
        description: string,
        image: string,
        actions: any[],
        servers: any[],
        auth: any
    ) {
        this.slug = slug;
        this.title = title;
        this.description = description;
        this.image = image;
        this.auth = new Auth(auth);

        actions.forEach((action: any) => {
            this.actions.push(
                new AppAction(action)
            )
        })

        for (const i in servers) {
            this.servers.push(
                new AppServer(
                    servers[i].url,
                    servers[i].variables
                )
            )
        }
    }

    getSlug(): string
    {
        return this.slug;
    }

    getTitle(): string
    {
        return this.title;
    }

    getActions(): AppAction[]
    {
        return this.actions;
    }

    getDescription(): string
    {
        return this.description;
    }

    getImage(): string
    {
        return this.image;
    }

    getServers(): AppServer[]
    {
        return this.servers;
    }

    getAuth(): Auth
    {
        return this.auth;
    }
}
