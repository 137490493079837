

import {Options, Vue} from "vue-class-component";
import AppParameterViewFactory from "@/components/Flowbuilder/Factory/AppParameterViewFactory";

@Options({
    emits: ['paramValue'],
    props: {
        id: String,
        parameters: Array,
        collectedData: Array,
        format: String,
        appAccountId: String,
        value: String
    },
    methods: {
        setParamValue(value: any) {
            this.$emit('paramValue', {id: this.$props.id, value: value})
        }
    },
    data() {
        return {
            view: null
        }
    },
    mounted() {
        const loader: AppParameterViewFactory = new AppParameterViewFactory();
        this.view = loader.create(this.$props.format);
    }
})

export default class ParameterWrapper extends Vue{}

