import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(this.view), {
    onParamValue: _ctx.setParamValue,
    value: this.$props.value,
    parameters: this.$props.parameters,
    collectedData: this.$props.collectedData,
    appAccountId: this.$props.appAccountId
  }, null, 40, ["onParamValue", "value", "parameters", "collectedData", "appAccountId"]))
}