export default class AppParameter
{
    private description = '';
    private name = '';
    private where = '';
    private required = false;
    private schema: any = {};
    value = '';

    constructor(
        name: string,
        description: string,
        where: string,
        required : boolean,
        schema: any
    ) {
        this.name = name;
        this.description = description;
        this.where = where;
        this.required = required;
        this.schema = schema;
    }

    getName(): string
    {
        return this.name;
    }

    setValue(value: string): void
    {
        this.value = value;
    }

    getValue(): string
    {
        return this.value;
    }
}
