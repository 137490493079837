import Description from "@/components/Flowbuilder/Helper/Description";

export default class DescriptionHelper {
    parse(description: string, title: string) : Description {

        if (description === '') {
            return new Description('',title);
        }

        let json: any = {};

        try {
            json = JSON.parse(description);
        } catch (e) {
            return new Description(title, description);
        }

        return new Description(
            json.title ?? '',
            json.description ?? '',
        );
    }
}