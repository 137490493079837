import App from "@/components/Flowbuilder/Apps/App";
import AppAction from "@/components/Flowbuilder/Apps/AppAction";

export default class AppCollection
{
    apps: App[] = [];

    constructor(
        apps: any[]
    ) {
        apps.forEach((app: any) => {
            this.apps.push(
                new App(
                    app.appName,
                    app.title,
                    app.description,
                    app.image,
                    app.actions,
                    app.server,
                    app.authType
                )
            )
        })
    }

    getApps(): App[]
    {
        return this.apps;
    }

    getAppBySlug(slug: string): App | null
    {
        this.apps.forEach((app: App) => {
            if (app.getSlug() === slug) {
                return app;
            }
        })

        return null;
    }

    getAppByOperationId(operationId: string): App | null
    {
        let found : App | null = null;

        this.apps.forEach((app: App) => {
            app.getActions().forEach((action: AppAction) => {
                if (action.getOperationId() === operationId) {
                    found = app;
                }
            })
        })

        return found !== null ? found : null;
    }
}