import AppParameter from "@/components/Flowbuilder/Apps/ActionParameter";
import AppResponse from "@/components/Flowbuilder/Apps/AppResponse";

export default class AppAction
{
    private description = '';
    private summary = '';
    private operationId = '';
    private parameters: AppParameter[] = [];
    private responses: any[] = [];
    private requestBody: any | null = null;

    constructor(
        action: any
    ) {
        this.operationId = action.operationId;
        this.requestBody = action.requestBody;
        this.description = action.requestBody;
        this.summary = action.summary;

        this.description = typeof action.description !== 'undefined'
            ? action.description
            : '';

        for (const i in action.parameters) {

            const parameter = action.parameters[i];

            this.parameters.push(
                new AppParameter(
                    parameter.name,
                    parameter.description,
                    parameter.in,
                    parameter.required,
                    parameter.schema,
                )
            );
        }

        this.responses = action.responses;
    }

    getRequestBody(): any
    {
        return typeof this.requestBody === 'undefined' || null
            ? null
            : this.requestBody;
    }

    getParameters(): AppParameter[]
    {
        return this.parameters;
    }

    getResponses(): AppResponse[]
    {
        return this.responses;
    }

    getOperationId(): string
    {
        return this.operationId;
    }

    getDescription(): string
    {
        return this.description;
    }

    getSummary(): string
    {
        return this.summary;
    }
}
