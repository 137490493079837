

import {Options, Vue} from "vue-class-component";

@Options({
    props: ['options', 'title', 'value', 'id','selected'],
    data() {
        return {
            instance: (new Date()).getTime(),
            selectedValue: null
        }
    },
    methods: {
        clearValue() {

            const dropElement = document
                .getElementById(this.$props.id + '-drop') as HTMLElement;

            dropElement.innerHTML = '';
        },
        showOptions(event: Event) {
            const parent = (event.currentTarget as HTMLElement).parentElement as HTMLElement;
            const optionList = parent.getElementsByClassName('options')[0] as HTMLElement;
            optionList.style.display = optionList.style.display === 'block'
                ? optionList.style.display = 'none'
                : optionList.style.display = 'block'
            ;
        },
        setSelectedValue(event: Event) {

            let target = event.currentTarget as HTMLElement;

            const parent = target?.parentElement?.parentElement as HTMLElement;
            const value = target?.getAttribute('data-value');
            const title = target?.getAttribute('data-title');
            const dropdown = parent.getElementsByClassName('dropdown-title')[0] as HTMLElement;

            dropdown.innerHTML = title ?? '';
            this.selectedValue = value;

            const options = parent.getElementsByClassName('option');

            for (let i = 0; i < options.length; i++) {
                options[i].className = 'option';
            }

            target.className = 'option selected';
            (parent.getElementsByClassName('options')[0] as HTMLElement)
                .style.display = 'none';

            this.$emit('optionSelected', value);
            event.preventDefault();
        }
    },
    mounted() {

        const optionElement: HTMLElement | null = document
            .getElementById(this.$props.id + '-options') ;

        const dropElement = document
            .getElementById(this.$props.id + '-drop') as HTMLElement;

        if (typeof optionElement === 'undefined' || optionElement === null) {
            return;
        }

        let options = optionElement.getElementsByClassName('option');

        for (let i in options) {

            let option = options[i];

            if (typeof option.getAttribute !== 'function') {
                continue;
            }

            let value = option.getAttribute('data-value');

            if (value === this.$props.value) {
                dropElement.innerHTML = option.innerHTML;
            }
        }
    },

    emits:["optionSelected"]
})

export default class Select extends Vue{}

