
import {Vue} from "vue-class-component";
import AuthOauth2 from "@/components/Flowbuilder/Views/Apps/Auth/Oauth2.vue";
import AuthNone from "@/components/Flowbuilder/Views/Apps/Auth/None.vue";
import App from "@/components/Flowbuilder/Apps/App";
import AuthApiKey from "@/components/Flowbuilder/Views/Apps/Auth/AuthApiKey.vue";
import BearerKey from "@/components/Flowbuilder/Views/Apps/Auth/BearerKey.vue";

export default class AuthViewFactory
{
    create(app: App): Vue
    {
        let component: any;

        const auth = app.getAuth();
        const type: string = auth.getType() !== null
            ? auth.getType() ?? ''
            : '';

        switch (type.toLowerCase()) {
        case 'oauth2':
            component = AuthOauth2;
            break;
        case 'bearerauth':
            component = BearerKey;
            break;
        case 'apikeyauth':
            component = AuthApiKey;
            break;
        default:
            component = AuthNone;
        }

        return component;
    }
}